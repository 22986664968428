var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        "min-height": "calc(100vh - 500px)",
        display: "flex",
        "justify-content": "center",
        "align-items": "center"
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "max-width": "560px",
            "background-color": "#fff",
            padding: "80px 30px",
            margin: "100px auto",
            "border-radius": "10px",
            flex: "1"
          }
        },
        [
          _c(
            "div",
            { staticStyle: { "max-width": "400px", margin: "0 auto" } },
            [
              _c("h1", { staticClass: "font-size-36 mb-2" }, [
                _vm._v("Page not found")
              ]),
              _c("p", { staticClass: "mb-3" }, [
                _vm._v(
                  "The page is deprecated, deleted, or does not exist at all"
                )
              ]),
              _c("h1", { staticClass: "font-size-80 mb-4 font-weight-bold" }, [
                _vm._v("404 —")
              ]),
              _c("router-link", { staticClass: "btn", attrs: { to: "/" } }, [
                _vm._v("← Go back to the home page")
              ])
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }